// Importo tema customizado para la app
@import './app/core/layout/dca-custom-theme.scss';

// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Import Reboot
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
// add the grid
@import '~bootstrap/scss/utilities';
// add css utilities
@import '~bootstrap/dist/css/bootstrap.css';
@import '~animate.css/animate.min';

@import "../node_modules/font-awesome/scss/font-awesome.scss";


.table-header {
  font-size: medium !important;
  font-weight: bold !important;
  text-align: center !important;
}

::ng-deep .magin-bottom-5 {
  margin-bottom: 5vh !important;
}

.invalid-feedback {
  display: block !important;
}

table {
  width: 100% !important;
  text-align: center !important;
}

.style-action {
  margin-right: 1%;
  text-align: center;
}

.titulo {
  padding-top: 5px;
  border-bottom: 2px solid #0f99e9;
  font-size: 20px;
  margin-bottom: 20px!important;
  color: #0f99e9;
}

.modal-class .mat-dialog-container{
  background: white;
}


.titulo-card {
  background-color: #115493;
  color: #fff;
  padding: 10px;
  margin-bottom: 30px !important;
  border-radius: 5px;
}

.mat-tab-label {
  background-color: #ddd;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ccc;
}

.mat-tab-label-content {
  font-size: 18px!important;
  font-weight: bold!important;
  color: #0f99e9;
}
.mat-tab-label-active {
  background-color: #009ee2;
  border-radius: 10px 10px 0 0;
  .mat-tab-label-content {
    color: #fff;
  }
}

.mat-tab-body {
  border: 1px solid #ddd;
  border-radius: 0 5px 5px 5px;
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: none!important;
}

.btn-crud:hover {
  color: #5cb85c;
}

.btn-white {
  color: #ffffff;
}

.btn-new {
  background-color: #bd172a !important;
}

.btn-margin {
  margin: 0 6px;
}

.button-disabled {
  color: #70757b !important;
}

.button-disabled:hover {
  color: #70757b !important;
}

.button-guardar {
  color: #5cb85c;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-bot-15 {
  padding-bottom: 15px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-0 {
  padding: 0;
}

.font-label {
  font-size: 30px !important;
}

.color-gris {
  color: #70757b !important;
  font-size: 30px !important;
}

.padding-top-08 {
  padding-top: 0.8rem;
}

.padding-bottom-08 {
  padding-bottom: 0.8rem;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-left-08 {
  padding-left: 0.8rem;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-right-08 {
  padding-right: 0.8rem;
}

.padding-top-04 {
  padding-top: 0.4rem !important;
}

.padding-15 {
  padding: 15px;
}

.margin-right-08 {
  margin-right: 0.8rem !important;
}

.margin-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.margin-left-5 {
  margin-left: 0.5rem !important;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-4 {
  margin-top: 4%;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-75-em {
  margin-top: 0.75em;
}

.filter-center {
  vertical-align: middle !important;
  text-align: center !important;
}

.text-allign-left {
  display: flex;
  align-items: center;
  text-align: left !important;
}

.width-100 {
  width: 100%;
}
.width-80 {
  width: 80%;
}
.width-30 {
  width: 30% !important;
}

.width-40 {
  width: 40% !important;
}

.width-50 {
  width: 50% !important;
}

.width-60 {
  width: 60% !important;
}

.notify-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  font-weight: bold;
  height: 15%;

  .mat-button {
    background: #CCF4DC;
    color: #155724;
    font-weight: bold;
    outline: 0;
  }
}

.notify-error {
  color: #571206 !important;
  background-color: #edd3c3 !important;
  border-color: #e6c6c3 !important;
  font-weight: bold;
  height: 15%;

  .mat-button {
    background: #edbdaa;
    color: #571206;
    font-weight: bold;
    outline: 0;
  }
}

.notify-warning {
  color: #dc7313 !important;
  background-color: #f1c289 !important;
  border-color: #e6c6c3 !important;
  font-weight: bold;
  height: 15%;

  .mat-button {
    background: #f1b771;
    color: #dc7313;
    font-weight: bold;
    outline: 0;
  }
}

.required {
  color: red;
}

.separacion-titulo-crud {
  padding-top: 20px;
}

.text-area {
  height: auto !important;
}

.imprimir {
  padding: 8px 0 0 20px;
}


.contenedor-scroll {
  overflow: auto !important;
  max-width: 100% !important;
}

.font-filtros {
  margin-left: -44px;
  font-size: 17px;
}

mat-card-actions {
  padding: (15px 0 15px 0) !important;
}

.mat-input-element:disabled {
  color: #818181 !important;
}

.mat-select-disabled .mat-select-value {
  color: #818181 !important;
}

.mat-cell {
  color: black;
  display: block!important;
  /*padding-top: 8px!important;*/
  text-align: center;
}
.mat-cell:first-child {
  text-align: center!important;
}

.mat-header-cell {
  text-align: center!important;
  display: block!important;
  min-height: 25px!important;
}

/*---------------------------------------------------------------*/
.mat-header-row.super-table, .mat-row.queue-element-row, .mat-row.queue-detail-row {
  padding: 0px!important;
}

.mat-header-row, .mat-row {
  min-height: auto!important;
  padding: 5px 0px;
}

.mat-table .sub-table .mat-row, .mat-table .sub-table .mat-header-row {
  padding: 0px!important;
}

.mat-cell .mat-column-expandedDetail {
  padding: 0px!important;
}

.mat-row .queue-element-row:not(.queue-expanded-row):hover {
  background: whitesmoke;
}

.mat-row .queue-element-row:not(.queue-expanded-row):active {
  background: #efefef;
}

.mat-row .queue-element-row {
  border-bottom-width: 0;
  padding: 0px!important;
}

.queue-element-detail {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-table.sub-table .mat-header-cell {
  border-bottom: 1px solid #ddd;
  font-weight: 500;
}
.mat-table.sub-table .mat-row {
  border-bottom: 1px solid #ddd;
}
.mat-table.sub-table .mat-row:last-child {
  border-bottom: 0;
}

.mat-card .margin-card {
  margin: 0 0 15px 0;
  border-radius: 0!important;
}
/*---------------------------------------------*/

.btn-view {
  color: darkblue;
}

.btn-view:hover {
  color: blue;
}

.btn-update {
  color: darkgoldenrod;
}

.btn-update:hover {
  color: yellow;
}

.btn-confirm {
  color: darkgreen !important;
}

.btn-confirm:hover {
  color: green;
}

.btn-delete {
  color: #b73838 !important;
}

.btn-delete:hover {
  color: #b21313;
}

.btn-cancel {
  color: chocolate;
}

.btn-cancel:hover {
  color: coral;
}

.btn-print {
  color: #1c7cd5;
}

.btn-print:hover {
  color: #0f99e9;
}

.btn:focus {
  box-shadow: none;
}

button:focus {
  outline: none !important;
}

.joyride-step__container {
  box-sizing: border-box;
  position: relative;
  color: #000;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 0 17px 1px #000 !important;
  border-radius: 6px !important;
  max-width: 30vw !important;
}

html,
body {
  height: 100%;
}

.readReceipt {
  height: auto;
}


.mat-step-header .mat-step-icon-state-done {
  background-color: transparent !important;
  color: $success;
  border: 3px solid $success !important;

  + .mat-step-label {
    color: $success !important;
  }
}

.mat-step-header .mat-step-icon-selected {
  background-color: transparent !important;
  color: #0f99e9;


  + .mat-step-label {
    font-size: 16px;
    color: #0f99e9 !important;
  }
}

.mat-step-header .mat-step-label {
  color: rgba(0, 0, 0, 0.54);
  /*  white-space: initial !important;*/
}

.mat-step-header .mat-step-icon {
  color: rgba(0, 0, 0, 0.54);
  /*  white-space: initial !important;*/
}

*::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #009ee2;
}

.separation-button {
  margin: 0 5px !important;
}

.strong {
  font-weight: bold !important;
}

td {
  .mat-checkbox {
    font-size: 10px !important;

    label {
      margin-bottom: 0 !important;
    }
  }
}

.cdk-overlay-container {
  z-index: 50000;
}

.btn-link:hover {
  cursor: pointer;
}

.warning-icon {
  vertical-align: top;
}

.warning-background {
  background-color: #C5C5C5;
  color: black;
}

//.mat-form-field-appearance-outline .mat-form-field-outline {
//  color: rgb(32, 32, 32);
//}
//
//mat-form-field {
//
//  .mat-hint,
//  input,
//  ::placeholder,
//  .mat-form-field-label,
//  .mat-dialog-container {
//    color: rgb(0, 0, 0);
//  }
//}
//
//.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
//  color: rgb(192, 192, 192);
//}

//
.mat-form-field-appearance-outline {
  .mat-form-field-label {
    color: rgba(0, 0, 0, 1) !important;
  }
}
.mat-form-field-appearance-outline.mat-form-field-disabled {
  .mat-form-field-label {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
//

.mat-input-element:disabled {
  color: rgb(129, 129, 129) !important;
}

.mat-icon .mat-icon-no-translate .material-icons .mat-icon-no-color {
  color: #022f53 !important;
}

.mat-header-cell {
  color: #0f99e9;
  font-size: 14px;
}

::ng-deep .mat-button-wrapper {
  color: #23919b;
}

.mat-dialog-container {
  font-family: 'Maven Pro', sans-serif !important;
  .mat-icon,
  .tittle-message {
    color: #022f53 !important;
  }
}

.alert-death {
  color: #022f53;
  background-color: #E7F2F3;
  border-color: #E7F2F3;
}

.alert-info {
  color: #022f53;
  background-color: rgba(#022f53, 0.6);
  border-color: rgba(#022f53, 0.6);
}

/*.card{
  display: inline-block;
  background: #fff;
  margin: 4px;
  transition: all 0.3s !important;
  height: 17.5rem;
  border-radius: 36px !important;
}*/
.mat-button {
  font-weight: 400 !important;
}

.card-header-accent {
  background: linear-gradient(60deg, #196ea4, #0f558a);
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -11px rgba(25, 110, 164, 0.6);
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -55px !important;
  border: 0;
}

.card-header-content {
  justify-content: space-between;
  align-items: flex-end;
}

.card-accent {
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(255 255 255 / 60%) !important;
}

.card-header-accent {
  .mat-button {
    color: white;
  }

  h5 {
    color: white !important;
    padding-left: 15px;
    font-weight: 450;
  }
}

.header-table-accent {
  text-align: center !important;
  text-transform: uppercase;
  color: #196ea4;
}

.row-footer {

  border-radius: 0;
  height: 90px !important;
  align-content: center !important;

  /*  background: linear-gradient(270deg, #033054, #0071CE);*/
  background-size: 400% 400%;

}

.login-dialog {
  .mat-dialog-container {
    box-shadow: none !important;
    background: transparent;
    color: transparent !important;
  }
}

.header-card{
  background: #009ee2;
  border-radius: 10px 10px 0 0px;
  color: #ffffff;
  height: 2.5rem;
}

.header-card h5{
  margin-top: 0.5rem;
}

::ng-deep .mat-table {
  background: #d7d9da !important;
  margin-bottom: 2rem !important;
}

@media (max-width: 460px) {
  .header-card{
    background: #009ee2;
    border-radius: 10px 10px 0 0px;
    color: #ffffff;
    height: 5rem!important;
    padding-bottom: 100px!important;
  }

  .header-card h5{
    margin-top: 0.5rem;
    font-size: 14px!important;
  }
}


@media screen and (max-width: 960px) {
  .header-card h5{
    margin-top: 0.5rem;
    font-size: 16px!important;
  }

  .mat-table {
    border: 0;
    vertical-align: middle
  }

  .mat-table caption {
    font-size: 1em;
  }

  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
    display: block;
  }

  .mat-table .mat-row {
    border-bottom: 1px solid #ddd;
    display: block;
  }
  /*
  .mat-table .mat-row:nth-child(even) {background: #CCC}
  .mat-table .mat-row:nth-child(odd) {background: #FFF}
  */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right!important;
    font-weight: bold;
    min-height:30px;
    margin-bottom: 2%;
    padding-top: 0px!important;
  }
  .mat-table .mat-cell:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;

    font-size: .85em;
  }
  .mat-table.sub-table .mat-header-cell {
    /*color: #0f99e9;*/
    border-bottom: 1px solid #ddd;
    font-weight: 500;
  }
  .mat-table.sub-table .mat-row {
    border-bottom: 5px solid #ddd;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    margin-top: 2%;
  }

  mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
    padding-left: 2px!important;
  }

  mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
    padding-right: 2px!important;
  }

  mat-cell, mat-header-cell, mat-footer-cell {
    min-height: 35px!important;
  }

  .mat-row.queue-detail-row {
    border-bottom: 5px solid #ddd;
  }

  .mat-row.queue-detail-row:last-child {
    border-bottom: 1px solid #ddd;
  }

  .mat-row.queue-row {
    border-bottom: 5px solid #ddd;
  }

  .mat-row.queue-row:last-child {
    border-bottom: 1px solid #ddd;
  }
}
